import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import Layout from "../components/shared/layout"
import SEO from "../components/seo"
import FindWork from "../components/homepage__findwork"
import Process from "../components/homepage__process"
import Review from "../components/homepage__review"
import Steps from "../components/homepage__steps"
import Benefits from "../components/homepage__benefit"
import Cta from "../components/homepage__cta"
import { graphql } from "gatsby"

export const query = graphql`
  {
    prismic {
      allJobs(lang: "fr-ca") {
        edges {
          node {
            title
            salaire
            city
            province
            type_de_salaire
            image_de_l_emploi
            _meta {
              uid
              lang
              type
            }
          }
        }
      }
    }
  }
`
const IndexPage = ({ data }) => {
  let jobs = data.prismic.allJobs.edges
  let pageDescription = "Recruter est aussi simple que prendre le THÉ. Centraliser tous vos besoins de personnels. Obtenez la solution parfaite pour la gestion de ressources humaines."
  return (
    <>
      <Layout french={true}>
        <SEO title="Accueil" description={pageDescription} lang="fr" />
        <FindWork french={true} jobs={jobs} />
        <Process french={true} />
        <Review french={true} />
        <Steps french={true} />
        <Benefits french={true} />
        <Cta french={true} />
      </Layout>
    </>
  )
}

export default IndexPage
